import React from "react";
import { Link, useNavigate } from "react-router-dom"; // Use navigate for routing
import { useFetchUsersQuery } from "../../redux/reducers/userApiSlice"; // Fetch users with RTK Query
import { useFetchMotorStatusQuery } from "../../redux/reducers/logApiSlice";
import { IoRefresh } from "react-icons/io5";
import ListLoader from "../common/ListLoader";

const UserList = () => {
  const { data: users, isLoading } = useFetchUsersQuery(); // Fetch users
  const { data: motorStatus, refetch: getMotorStatus } =
    useFetchMotorStatusQuery(); // Fetch motor status
  const navigate = useNavigate(); // Hook for programmatic navigation

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between mb-4">
        <h3>User List</h3>
        <div>
          <button
            className="btn btn-primary me-2"
            onClick={() => getMotorStatus()}
          >
            <IoRefresh style={{ marginTop: "-5px" }} size={20} />
          </button>
          <button
            className="btn btn-primary"
            onClick={() => navigate("/add-user")}
          >
            Add New User
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="table table-bordered">
          <thead className="table-secondary">
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>RFID</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <>
                <tr>
                  <td colSpan="5" className="text-center">
                    <ListLoader />
                  </td>
                </tr>
              </>
            ) : !users || users?.length === 0 ? (
              <tr>
                <td colSpan="5" className="text-center">
                  No logs found
                </td>
              </tr>
            ) : (
              users?.map((user) => (
                <tr key={user._id}>
                  <td>
                    {user.name}
                    {user.name === motorStatus?.fullName && (
                      <div class="green-dot ms-2"></div>
                    )}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.rfid}</td>
                  <td>{user.status ? "Active" : "Inactive"}</td>
                  <td>
                    <Link
                      to={`/edit-user/${user._id}`}
                      className="btn btn-sm btn-warning"
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserList;
