import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define API Slice using RTK Query
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://deviceapi.rfidpumpoptr.co.in/user" }), // Replace '/api' with your actual API base URL
  tagTypes: ["userList", "singleUser"],
  endpoints: (builder) => ({
    fetchUsers: builder.query({
      query: () => ({
        url: "/",
        method: "GET",
        headers: {
          ['x-auth-token']: "NextAuthTokenKey " +  JSON.parse(localStorage.getItem('user'))?.token
        }
      }), // Adjust the endpoint to match your API
      providesTags: ["userList"]
    }),
    fetchUserById: builder.query({
      query: (id) => ({
        url: `/${id}`,
        method: "GET",
        headers: {
          ['x-auth-token']: "NextAuthTokenKey " +  JSON.parse(localStorage.getItem('user'))?.token
        }
      }),
      providesTags: (result, error, id) => [{ type: "singleUser", id }],
    }),
    addUser: builder.mutation({
      query: (user) => ({
        url: "/",
        method: "POST",
        body: user,
        headers: {
          ['x-auth-token']: "NextAuthTokenKey " +  JSON.parse(localStorage.getItem('user'))?.token
        }
      }),
      invalidatesTags: ["userList"]
    }),
    updateUser: builder.mutation({
      query: ({ id, user }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: user,
        headers: {
          ['x-auth-token']: "NextAuthTokenKey " +  JSON.parse(localStorage.getItem('user'))?.token
        }
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "singleUser", id }, "userList"],
    }),
    login: builder.mutation({
      query: (body) => ({
        url: `login`,
        method: "POST",
        body: body,
        headers: {
          ['x-auth-token']: "NextAuthTokenKey " +  JSON.parse(localStorage.getItem('user'))?.token
        }
      }),
    }),
  }),
});

export const {
  useFetchUsersQuery,
  useFetchUserByIdQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useLoginMutation
} = userApi;
