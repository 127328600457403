import React from "react";

const ListLoader = () => {
  return (
    <>
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-border text-primary" role="status" />
        <span className="ms-2">Loading...</span>
      </div>
    </>
  );
};

export default ListLoader;
