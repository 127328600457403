import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddUserMutation,
  useFetchUserByIdQuery,
  useUpdateUserMutation,
} from "../../redux/reducers/userApiSlice";

const UserForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams(); // For Edit mode

  // Mutations for add and update
  const [addUser, { isLoading: isAdding }] = useAddUserMutation();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  // Fetch user data in case of edit
  const { data: userData, isLoading: isFetching } = useFetchUserByIdQuery(id, {
    skip: !id, // Skip fetching if there's no ID (i.e., add mode)
  });

  // Form validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    rfid: Yup.string().required("RFID is required"),
    status: Yup.string().required("Status is required"),
  });

  // Formik initialization
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      rfid: "",
      status: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (id) {
        // Update user
        await updateUser({ id, user: values });
        navigate("/users"); // Redirect after updating
      } else {
        // Add new user
        await addUser(values);
        navigate("/users"); // Redirect after adding
      }
    },
    enableReinitialize: true, // Enable reinitialization when userData is fetched
  });

  // Populate the form with fetched data in edit mode
  useEffect(() => {
    if (userData) {
      formik.setValues({
        name: userData.name,
        email: userData.email,
        rfid: userData.rfid,
        status: userData.status,
      });
    }
  }, [userData]);

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-6">
          <h3>{id ? "Edit User" : "Add User"}</h3>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3 mt-4">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className={`form-control ${
                  formik.touched.name && formik.errors.name ? "is-invalid" : ""
                }`}
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="invalid-feedback">{formik.errors.name}</div>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className={`form-control ${
                  formik.touched.email && formik.errors.email
                    ? "is-invalid"
                    : ""
                }`}
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="invalid-feedback">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="rfid" className="form-label">
                RFID
              </label>
              <input
                type="text"
                className={`form-control ${
                  formik.touched.rfid && formik.errors.rfid ? "is-invalid" : ""
                }`}
                id="rfid"
                name="rfid"
                value={formik.values.rfid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.rfid && formik.errors.rfid ? (
                <div className="invalid-feedback">{formik.errors.rfid}</div>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className={`form-control ${
                    formik.touched.password && formik.errors.password
                      ? "is-invalid"
                      : ""
                  }`}
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // disabled={id}
                />
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => setShowPassword(!showPassword)}
                  // disabled={id}
                  aria-label={showPassword ? "Hide password" : "Show password"}
                >
                  {showPassword ? (
                    <i className="bi bi-eye-slash"></i> // Bootstrap eye-slash icon
                  ) : (
                    <i className="bi bi-eye"></i> // Bootstrap eye icon
                  )}
                </button>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className="invalid-feedback">{formik.errors.password}</div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="status" className="form-label">
                Status
              </label>
              <select
                className={`form-control ${
                  formik.touched.status && formik.errors.status
                    ? "is-invalid"
                    : ""
                }`}
                id="status"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select Status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              {formik.touched.status && formik.errors.status ? (
                <div className="invalid-feedback">{formik.errors.status}</div>
              ) : null}
            </div>

            <button type="submit" className="btn btn-primary">
              {id
                ? isUpdating
                  ? "Updating..."
                  : "Update"
                : isAdding
                ? "Saving..."
                : "Save"}
            </button>
            <button
              className="btn btn-danger mx-3"
              onClick={() => navigate("/users")}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
