import React, { useEffect, useState } from "react";
import { useFetchUsersQuery } from "../../redux/reducers/userApiSlice";
import {
  useFetchMotorStatusQuery,
  useFetchUserLogsQuery,
} from "../../redux/reducers/logApiSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { useFetchMenubyIdQuery } from "../../redux/reducers/menulistApiSlice";
import ListLoader from "../common/ListLoader";
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

const inr = Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  currencySign: "standard",
});

const HomePage = () => {
  const { data: users = [] } = useFetchUsersQuery();
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState("today");
  const [customDateRange, setCustomDateRange] = useState([
    dayjs().startOf("day").toDate(),
    dayjs().endOf("day").toDate(),
  ]);
  const [startDate, endDate] = customDateRange;
  const [currentMotorStatus, setCurrentMotorStatus] = useState({
    user: "",
    status: "",
  });

  const formatDate = (date) =>
    date
      ? dayjs(date).format("YYYY-MM-DD HH:mm")
      : dayjs().format("YYYY-MM-DD HH:mm");

  const formattedDateRange = `${formatDate(startDate)} - ${formatDate(
    endDate
  )}`;

  const {
    data: userLogs = [],
    isFetching: loadingLogs,
    refetch: getLogs,
  } = useFetchUserLogsQuery(
    {
      userId: selectedUserId,
      date: formattedDateRange,
    },
    { skip: !selectedUserId }
  );

  const {
    data: motorStatus,
    isLoading: loadingMotor,
    refetch: getMotorStatus,
  } = useFetchMotorStatusQuery();

  const { data: price } = useFetchMenubyIdQuery("Price");
  const { data: electricity, refetch: getElectricity, isLoading: loadingElecStatus } = useFetchMenubyIdQuery("Electricity");

  const handleUserChange = (e) => {
    setSelectedUserId(e.target.value);
  };

  const calculateDuration = (logs) => {
    return logs.reduce((total, log) => {
      const totalDuration = log.startStopTimes.reduce((sum, entry) => {
        const start = dayjs(entry.startTime);
        const stop = entry.stopTime ? dayjs(entry.stopTime) : dayjs();
        return sum + stop.diff(start, "second");
      }, 0);
      return total + totalDuration;
    }, 0);
  };

  const totalDurationInSeconds = calculateDuration(userLogs);

  const convertDuration = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return { hours, minutes, seconds };
  };

  const {
    hours: totalHours,
    minutes: totalMinutes,
    seconds: totalSeconds,
  } = convertDuration(totalDurationInSeconds);

  const convertDurationToHours = (totalSeconds) => {
    return totalSeconds / 3600;
  };

  const totalDurationInHours = convertDurationToHours(totalDurationInSeconds);

  const calculateBill = (durationInHours, pricePerHour) => {
    return durationInHours * pricePerHour;
  };

  const totalBill = calculateBill(
    totalDurationInHours,
    parseInt(price?.value, 10)
  );

  const handleDateRangeChange = (e) => {
    const value = e.target.value;
    setSelectedDateRange(value);

    // Set date range based on selection
    if (value === "today") {
      setCustomDateRange([
        dayjs().startOf("day").toDate(),
        dayjs().endOf("day").toDate(),
      ]);
    } else if (value === "yesterday") {
      setCustomDateRange([
        dayjs().subtract(1, "day").startOf("day").toDate(),
        dayjs().subtract(1, "day").endOf("day").toDate(),
      ]);
    } else if (value === "thisMonth") {
      setCustomDateRange([
        dayjs().startOf("month").toDate(),
        dayjs().endOf("month").toDate(),
      ]);
    } else if (value === "lastMonth") {
      setCustomDateRange([
        dayjs().subtract(1, "month").startOf("month").toDate(),
        dayjs().subtract(1, "month").endOf("month").toDate(),
      ]);
    } else if (value === "custom") {
      setCustomDateRange([null, null]); // Allow user to select a range
    }
  };

  useEffect(() => {
    if (motorStatus) {
      setCurrentMotorStatus({ user: motorStatus?.fullName, status: "Running" });
    } else {
      setCurrentMotorStatus({ user: "", status: "Stopped" });
    }
  }, [motorStatus]);

  const refetchMotorLogs = () => {
    if (selectedUserId) {
      getLogs();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getMotorStatus();
      getElectricity();
      if (selectedUserId) {
        refetchMotorLogs();
      }
    }, 10000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [selectedUserId]);

  return (
    <div className="container mt-4">
      <h3 className="mb-2">Motor Usage Logs</h3>
      {loadingMotor || loadingElecStatus ? (
        <>
          <div class="d-flex align-items-center my-3">
            <div
              class="spinner-border text-primary spinner-border-sm"
              role="status"
            />
            <span className="ms-2">Loading...</span>
          </div>
        </>
      ) : (
        currentMotorStatus?.status && (
          <div className="mb-3">
            <div
              className="fw-semibold"
              style={{
                color:
                  currentMotorStatus.status === "Running" ? "#002bb1" : "red",
                fontSize: "1.1rem",
              }}
            >
              Motor Status: {currentMotorStatus.status}
              {currentMotorStatus.user && (
                <span> by {currentMotorStatus.user}</span>
              )}
              .
            </div>
            <div
              className="fw-semibold"
              style={{
                color: electricity?.value === "true" ? "green" : "red",
                fontSize: "1.1rem",
              }}
            >
              Electricity Status: {electricity?.value === "true" ? "Available" : "Not Available"}.
            </div>
          </div>
        )
      )}
      <div className="d-flex justify-content-md-between flex-sm-row flex-column mb-2">
        <div className="d-flex flex-column flex-sm-row">
          <div className="me-3">
            <select
              className="form-select mb-2"
              onChange={handleUserChange}
              value={selectedUserId}
            >
              <option value="">Select a user</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>
          <div className="me-3">
            <select
              className="form-select mb-2"
              onChange={handleDateRangeChange}
              value={selectedDateRange}
            >
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="thisMonth">This Month</option>
              <option value="lastMonth">Last Month</option>
              <option value="custom">Custom Range</option>
            </select>
          </div>
          {selectedDateRange === "custom" && (
            <div className="me-3">
              <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => setCustomDateRange(update)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                placeholderText="Select date range"
                isClearable
              />
            </div>
          )}
        </div>
        <button
          className="btn btn-primary mb-3 me-3 mt-2 mt-sm-0"
          onClick={() => {
            selectedUserId && refetchMotorLogs();
            getMotorStatus();
            getElectricity();  
          }}
        >
          Refresh
        </button>
      </div>

      {!selectedUserId && (
        <div
          class="alert alert-secondary d-flex align-items-center"
          role="alert"
        >
          <div>Please select a user to view logs.</div>
        </div>
      )}

      {selectedUserId && (
        <div className="overflow-x-auto">
          <h3>
            Duration Logs for{" "}
            {users.find((user) => user._id === selectedUserId)?.name}
          </h3>
          <p>
            Total Duration: {totalHours} hours {totalMinutes} minutes and{" "}
            {totalSeconds} seconds
          </p>
          <h5>Total Bill: {inr.format(totalBill)} </h5>

          <table className="table table-bordered table-hover mt-3">
            <thead className="table-secondary">
              <tr>
                <th>Start Time</th>
                <th>Stop Time</th>
                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              {loadingLogs ? (
                <>
                  <tr>
                    <td colSpan="3" className="text-center">
                      <ListLoader />
                    </td>
                  </tr>
                </>
              ) : userLogs.length === 0 ? (
                <tr>
                  <td colSpan="3" className="text-center">
                    No logs found
                  </td>
                </tr>
              ) : (
                userLogs.map((log) =>
                  log.startStopTimes.map((entry) => {
                    const start = dayjs(entry.startTime);
                    const stop = entry.stopTime
                      ? dayjs(entry.stopTime)
                      : dayjs();
                    const duration = stop.diff(start, "second");

                    const durationHours = Math.floor(duration / 3600);
                    const durationMinutes = Math.floor((duration % 3600) / 60);
                    const durationSeconds = duration % 60;

                    return (
                      <tr key={entry._id}>
                        <td>{start.format("DD-MM-YYYY hh:mm:ss A")}</td>
                        <td>
                          {entry.stopTime
                            ? stop.format("DD-MM-YYYY hh:mm:ss A")
                            : "Still Running"}
                        </td>
                        <td>
                          {durationHours} hours {durationMinutes} minutes and{" "}
                          {durationSeconds} seconds
                        </td>
                      </tr>
                    );
                  })
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default HomePage;
